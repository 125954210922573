import React, { useState, useEffect } from 'react'
import { Typography } from '@/elements/src'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { IconArrowUpRight } from '@hubble/icons'
import * as webTokens from '@hubble/tokens/web'
import { Container, styled } from '@mui/material'
import Link from 'next/link'

import {
  STICKY_FOOTER_BOTTOM_ID,
  STICKY_FOOTER_LINK_TEXT,
  STICKY_FOOTER_TITLE,
  STICKY_FOOTER_TITLE_MOBILE,
} from '../constants'

const StyledFooter = styled('footer')`
  bottom: 0;
  width: 100%;
  background-color: ${webTokens.color.base.black};
  color: ${webTokens.color.base.white};
  padding: 20px;
`

const StyledDiv = styled('div')`
  display: flex;
  align-items: center;

  .about--link {
    color: ${webTokens.color.base.white};
    text-decoration: none;
  }

  .arrowupright--icon {
    position: relative;
    top: 2px;
    left: 1px;
  }
`

const StickyFooter = () => {
  const [showFooter, setShowFooter] = useState(true)
  const matches750 = useMediaQueryState('(max-width:750px)')

  useEffect(() => {
    const footer = document.getElementById('sticky-footer')
    // where sticky footer stops
    const targetEndComponent = document.getElementById(STICKY_FOOTER_BOTTOM_ID)

    const handleScroll = () => {
      if (footer && targetEndComponent) {
        const targetEndComponentRect =
          targetEndComponent.getBoundingClientRect()

        // Check if the specific component is in the viewport
        if (targetEndComponentRect.top < global?.window?.innerHeight) {
          setShowFooter(false)
        } else {
          setShowFooter(true)
        }
      }
    }

    if (footer && targetEndComponent) {
      global?.window?.addEventListener('scroll', handleScroll)
    }

    return () => {
      global?.window?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <StyledFooter
      id='sticky-footer'
      sx={{ position: showFooter ? 'sticky' : 'absolute' }}
    >
      <Container
        maxWidth='xl'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          whiteSpace: 'nowrap',
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
        }}
      >
        <div />

        <div>
          {matches750 ? (
            <StyledDiv>
              <Link href='/about' className='about--link'>
                <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                  {STICKY_FOOTER_TITLE}
                </Typography>
              </Link>
              <IconArrowUpRight className='arrowupright--icon' size='sm' />
            </StyledDiv>
          ) : (
            <Typography>{STICKY_FOOTER_TITLE_MOBILE}</Typography>
          )}
        </div>

        <div>
          {matches750 ? null : (
            <StyledDiv>
              <Link href='/about' className='about--link'>
                <Typography>{STICKY_FOOTER_LINK_TEXT}</Typography>
              </Link>
              <IconArrowUpRight className='arrowupright--icon' />
            </StyledDiv>
          )}
        </div>
      </Container>
    </StyledFooter>
  )
}

export default StickyFooter
